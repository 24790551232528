<template>
    <div class="footer">
        <div class="footer__contact d-flex flex-column justify-center mb-md-16 mb-sm-12 mb-8">
            <div class="d-flex justify-center mb-4">
                <a class="second-text grey--text neris fw-600 mr-8" target="_blank" href="mailto:info@mk-mobile.online">
                    info@mk-mobile.online
                </a>

                <a class="second-text grey--text neris fw-600" href="tel:88002227269">
                    8 (800) 222 72 69
                </a>
            </div>

            <div class="">
                <p v-for="item of requisites" :key="item.title"
                   class="second-text grey--text fw-600 mb-2 neris d-block">
                    {{ item.title }} {{ item.value }}
                </p>
            </div>
        </div>


        <div class="footer__social d-flex justify-center mb-md-12 mb-sm-10 mb-8">
            <a target="_blank" class="mr-4" v-for="item in socials" :key="item.link" :href="item.link">
                <img :src="item.img" alt="">
            </a>
        </div>

        <!--        <p class="second-text font-weight-light text-center">Заем предоставляется микрофинансовой организацией ООО "МКК-->
        <!--            "Кредит Лайн", регистрационный номер записи в государственном реестре микрофинансовых организаций-->
        <!--            2120150001955 в соответствии со свидетельством ФСФР России №0002195 от 14.08.2012.</p>-->
    </div>
</template>

<script>
export default {
    data: () => ({
        socials: [
            {img: require('@/assets/img/vk.svg'), link: 'https://vk.com/pl_paylate'},
        ],

        requisites: [
            {title: 'ИНН:', value: '5047146580'},
            {title: 'КПП:', value: '504701001'},
            {title: 'ОГРН:', value: '1135047010779'},
            {title: 'ОКПО:', value: '18208296'},
            {title: 'Расчетный счет:', value: '40702810000000210725'},
            {title: 'Банк:', value: 'ПАО "ПРОМСВЯЗЬБАНК"'},
            {title: 'БИК:', value: '044525555'},
            {title: 'Корр. счет:', value: '30101810400000000555'},
            {
                title: 'Юридический адрес:',
                value: '141407, Московская обл., г. Химки, ул.   Лавочкина, стр. 2А, этаж/пом. 2/19'
            },
            {
                title: 'Фактический адрес:',
                value: '141407, Московская обл., г. Химки, ул.   Лавочкина, стр. 2А, этаж/пом. 2/19'
            },
        ]
    })
}
</script>

<style lang="scss" scoped>
.footer {
    &__social {
        a {
            transition: 0.3s;

            &:hover {
                transform: scale(0.96);
            }

            &:active {
                transform: scale(0.9);
            }
        }
    }

    &__contact {
        padding: 11px 0 13px 0;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
