import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true,
    },

    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                pink: '#EB3B66',
                black: '#0A0A0D',
                blue: '#007AFF',
                grey: '#656565',
                // yellow: "#FBECAE",
                // purple: "#9c6cfe",
                // grey: "#848485",
                // green: "#26A212",
                // red: "#FA656E",
            },
        },
    },
});
