<template>
    <v-app>
        <Home/>
        <Info/>
        <Documents/>
        <Questions/>
    </v-app>
</template>

<script>
import Home from "./components/screens/Home";
import Info from "./components/screens/Info";
import Documents from "@/components/screens/Documents";
import Questions from "@/components/screens/Questions";

export default {
    name: "App",
    components: {
        Questions,
        Documents,
        Home,
        Info,
    }
};
</script>
