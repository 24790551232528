<template>
    <div class="info screen">
        <div class="container">
            <div class="info__top text-center">
                <h2 class="main-title">
                    Как это работает
                </h2>

                <h3 class="main-text grey--text mt-md-8 mt-6">
                    Сервис «Оплата частями» помогает вам разделить сумму покупки на четыре равных платежа — без комиссии
                    и дополнительных плат для клиента
                </h3>
            </div>

            <div class="info__body main-block my-md-16 my-sm-12 my-8">
                <v-row>
                    <v-col sm="4" cols="12" class="info__block" v-for="item in items" :key="item.title">
                        <div class="info__block-img">
                            <img :src="item.img" alt="">
                        </div>

                        <h4 class="block-title mt-md-6 mt-sm-5 mt-4">
                            {{ item.title }}
                        </h4>
                    </v-col>
                </v-row>
            </div>
            <h3 class="big-text font-weight-semibold">
                Клиент получает покупку, сразу после оплаты первой части в размере 25% от всей суммы. Три платежа будут
                списываться автоматически с подвязанной банковской карты клиента, с шагом раз в месяц.
            </h3>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',

    data: () => ({
        items: [
            {
                img: require('@/assets/img/add-to-cart.svg'),
                title: 'Клиент формирует корзину',
            },
            {
                img: require('@/assets/img/one-finger-select-hand-gesture.svg'),
                title: 'Выбирает способ оплаты: «Оплата частями»',
            },
            {
                img: require('@/assets/img/page-edit.svg'),
                title: 'Оформить просто, заполни четыре поля',
            },
        ]
    })
}
</script>

<style lang="scss" scoped>
.v-application .info {
    //padding: 128px 0;
    background-color: #F5F5F5 !important;

    &__top,
    .big-text {
        max-width: 750px;
        margin: 0 auto;
        text-align: center;
    }

    &__body {
        padding: 80px 64px;
    }

    &__block {
        text-align: center;
        position: relative;

        &-img {
            width: 64px;
            height: 64px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(311.28deg, #EB3B66 5.37%, #FF688D 95.98%);
            box-shadow: -4px -4px 24px rgba(235, 59, 102, 0.64);
            border-radius: 50%;
        }

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            width: 1px;
            height: 100%;
            background: linear-gradient(265deg, rgba(231, 231, 234, 0.0001) 0%, #E7E7EA 52.47%, rgba(231, 231, 234, 0.0001) 100%);
        }

        &:last-child {
            &:after {
                content: none;
            }
        }
    }

    @media(max-width: 959px) {
        &__body {
            padding: 32px;
        }

        &__block-img {
            width: 60px;
            height: 60px;
        }
    }

    @media(max-width: 768px) {
        &__body {
            padding: 32px 24px;
        }
    }

    @media(max-width: 599px) {
        &__body {
            padding: 0px 24px 48px 24px;

            margin: 40px -24px 32px -24px !important;
        }

        &__block {
            &:after {
                content: none;
            }

            &-img {
                width: 56px;
                height: 56px;
            }

            margin-top: 48px;
        }
    }
}
</style>
