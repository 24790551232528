<template>
    <v-expansion-panels v-bind="$attrs" accordion>
        <v-expansion-panel v-for="(accordion, i) in accordions" :key="accordion.title + i">
            <v-expansion-panel-header :expand-icon="icon">
                <h5 class="main-text font-weight-semibold black--text">{{ accordion.title }}</h5>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <slot></slot>
                <div class="accordion-body second-text">
                    {{ accordion.text }}
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    props: {
        accordions: {
            type: Array,
            default: () => [{id: 0, action: true}]
        },

        icon: String,
    },
}
</script>

<style lang="scss">
.v-expansion-panel {
    width: 100%;
    background: radial-gradient(100% 100% at 0% 0%, rgba(10, 10, 13, 0.075) 0%, rgba(10, 10, 13, 0.0225) 100%) !important;
    backdrop-filter: blur(8px) !important;
    border-radius: 16px !important;
    margin-top: 16px !important;
    border: 1px solid #D5D5D6;
    //border-image-source: radial-gradient(100% 100% at 0% 0%, rgba(10, 10, 13, 0.3) 0%, rgba(255, 255, 255, 0.35) 0.01%, rgba(10, 10, 13, 0.1) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    box-shadow: none !important;

    &-header {
        padding: 14px 24px 18px 24px;

        h5 {
            margin-right: 24px;
            font-family: Neris, sans-serif;
            font-weight: 600;
        }
    }

    &:after {
        content: none !important;
    }

    &:before {
        box-shadow: none !important;
    }

    .accordion-body {
        padding-top: 16px;
        font-weight: 300;
        border-top: #C8C8C8 1px solid;
    }

    &--active {

    }


    @media(max-width: 599px) {
        &-header {
            align-items: flex-start;
            padding: 10px 16px 14px 16px;
        }

        h5 {
            font-size: 16px !important;
            margin-right: 16px !important;
        }

        &-content__wrap {
            padding: 0 16px 20px 16px !important;
        }
    }
}
</style>
