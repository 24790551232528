<template>
    <div class="questions screen">
        <div class="container">
            <v-row class="justify-center">
                <v-col md="8" sm="10">
                    <h2 class="main-title text-center">
                        Частые вопросы
                    </h2>

                    <Accordion
                        :accordions="questions"
                        class="mb-md-16 mt-md-12 mb-sm-14 mt-sm-10 mb-8 mt-4"
                        icon="mdi-chevron-down"
                    />
                    <Footer/>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import Accordion from "@/components/Accordion";
import Footer from "@/components/Footer";

export default {
    components: {Footer, Accordion},
    data: () => ({
        questions: [
            {
                title: '1. Как купить товар с сервисом «Оплата частями»?',
                text: 'Для покупки товара нужно зайти на сайт партнёра, положить в корзину товары на сумму от 3 000 до 100 000 рублей и выбрать способ оплаты «Оплата частями». ',
            },
            {
                title: '2. Есть какие-то требования к тем, кто хочет воспользоваться сервисом?',
                text: 'Необходимо только наличие дебетовой или кредитной картылюбого банка. Сервис доступен лицам 18-70 лет',
            },
            {
                title: '3. Мне нужны какие-то документы, чтобы платить частями?',
                text: 'Без формальностей, документы не нужны! Для оформления достаточно указать только ФИО, дату рождения, номер телефона, email и данные банковской карты для оплаты',
            },
            {
                title: '4. Это кредит? Проверяете ли вы кредитную историю?',
                text: 'Нет, это не кредит. Кредитную историю не проверяем и не заключаем кредитный договор с покупателем.',
            },
            {
                title: '5. Я заплачу проценты или иные платежи за использование сервиса?',
                text: 'Сервис позволяет разделить оплату на 4 равные части, выплатите только сумму покупки.',
            },
            {
                title: '6. На сколько у Вас гибкие условия?',
                text: 'Для Вас доступна возможность изменения даты платежа и пролонгация графика',
            },
            {
                title: '7. Где можно будет посмотреть дату списания платежей? Мне придут напоминалки?',
                text: 'Дату списания платежей можно будет посмотреть в своём личном кабинете. В день списания напомним заранее - придёт СМС',
            },
            {
                title: '8. А что если я потеряю карту, с которой должны списываться платежи или решу поменять ее? Где это сделать?',
                text: 'Карту можно будет поменять в личном кабинете. Для этого нужно перейти в раздел «Способ оплаты» и нажать на «Изменить карту».',
            },
            {
                title: '9. Как оформить возврат, если товар не подошел?',
                text: 'Для   оформления   возврата   или   аннулирования   заказа,необходимо обратиться в торговую организацию, где был оформлен заказ.Вернуть можно всю покупку или ее часть, точная сумма возврата будет рассчитана исходя из того, сколько и какие платежи вы внесли на момент возврата. Деньги вернутся на карту в течение 10 рабочих дней. Срок зависит от банка-эмитента и платежной системы.\n',
            },
        ]
    })
}
</script>

<style lang="scss" scoped>
.questions {
    .main-block {
        height: 100%;
    }
}
</style>
