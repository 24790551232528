<template>
    <div class="home screen">
        <div class="container">
            <div class="home__logo pb-sm-3 pb-0">
                <img src="@/assets/img/pl-logo.svg" alt="">
            </div>

            <div class="home__top text-center mb-md-14 mb-8 mt-md-16 mt-sm-8 mt-4">
                <h1 class="main-title">
                    <span class="pink--text">"Оплата частями" -</span> <br>
                    новый сервис оплаты покупок
                </h1>
            </div>

            <v-row>
                <v-col md="3" sm="6" v-for="item in items" :key="item.description" class="home__block py-sm-4 py-2">
                    <div class="home__block-img">
                        <img :src="item.img" alt="">
                    </div>

                    <div class="home__block-item main-block">
                        <img class="mr-4" :src="item.descriptionImage" alt="">

                        <p class="second-text">{{ item.description }}</p>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',

    data: () => ({
        items: [
            {
                img: require('@/assets/img/home-1.png'),
                description: 'Покупай сейчас, плати позже. Равными частями',
                descriptionImage: require('@/assets/img/home-description-1.svg'),
            },
            {
                img: require('@/assets/img/home-2.png'),
                description: 'Сумма делится на 4 части',
                descriptionImage: require('@/assets/img/home-description-2.svg'),
            },
            {
                img: require('@/assets/img/home-3.png'),
                description: 'Нужен только мобильный телефон',
                descriptionImage: require('@/assets/img/home-description-3.svg'),
            },
            {
                img: require('@/assets/img/home-4.png'),
                description: 'Вам одобрено!',
                descriptionImage: require('@/assets/img/home-description-4.svg'),
            },
        ]
    })
}
</script>

<style lang="scss" scoped>
.home {
    padding: 69px 0 92px 0;
    background: linear-gradient(180deg, rgba(233, 233, 233, 0) 0%, #E9E9E9 100%);

    &__block {
        flex: 0 0 100% !important;

        &-img {
            height: 380px;
            width: 100%;

            img {
                max-width: 100%;
                max-height: 100%;
            }
        }

        &-item {
            padding: 21px 28px 20px 24px;
            height: calc(100% - 380px);
            display: flex;
            align-items: center;

            p {
                line-height: 1.2em;
            }
        }
    }

    @media(max-width: 959px) {
        padding: 32px 0 64px 0;

        &__block {
            flex: 0 0 100% !important;

            &-img {
                height: 360px;
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    @media(max-width: 599px) {
        padding: 32px 0 24px 0;

        &__block {
            &-item {
                padding: 16px;
                height: 100%;
            }

            &-img {
                display: none;
            }
        }

        &__logo {
            width: 100px;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }
    }
}
</style>
