<template>
    <div class="documents screen">
        <div class="container">
            <h2 class="main-title text-center">
                Документы
            </h2>

            <v-row class="mt-3 justify-center">
                <v-col md="4" sm="6" class="mt-md-8 mt-sm-6 mt-4 py-0" v-for="(item, i) in documents"
                       :key="item.text + i">
                    <div class="d-flex align-center main-block pa-6">
                        <img class="mr-6" src="@/assets/img/document_icon.svg" alt="">

                        <a :href="item.link" target="_blank" class="main-text blue--text">
                            {{ item.text }}
                        </a>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        documents: [
            {
                text: 'Политика безопасности платежей',
                link: 'https://local-bnpl.mk-mobile.online/storage/docs/mk-mobile/secure.pdf'
            },
            {
                text: 'Условия поручения по приобретению и оплате товаров, работ и услуг',
                link: 'https://local-bnpl.mk-mobile.online/storage/docs/mk-mobile/rules.pdf'
            },
            {
                text: 'Согласие на обработку персональных данных',
                link: 'https://local-bnpl.mk-mobile.online/storage/docs/mk-mobile/agreement.pdf'
            },
            {
                text: 'Политика обработки персональных данных Общества с ограниченной ответственностью «МК-Мобайл»',
                link: 'https://local-bnpl.mk-mobile.online/storage/docs/mk-mobile/polite.pdf'
            },
            {
                text: 'Общие условия соглашения о новации',
                link: 'https://local-bnpl.mk-mobile.online/storage/docs/mk-mobile/novation.pdf'
            },
            {
                text: 'Агентский договор',
                link: 'https://local-bnpl.mk-mobile.online/storage/docs/mk-mobile/agent.pdf'
            },
        ]
    }),
}
</script>

<style lang="scss" scoped>
.documents {
    .main-block {
        height: 100%;
    }

    p {
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
</style>
